// function getTotalSupply() {
//   var fetchedSupply;
//   fetch('https://api.polygonscan.com/api?module=stats&action=tokenCsupply&contractaddress=0xd902b485fc43b813289690a923820fe08cb251de&apikey=FU5NCD7Z2QUEE8Y8D3GMK4FA4MCU96MT43')
//   .then(function(response) {
//     return response.json();
//   }).then(function(json) {
//     fetchedSupply = json.result;
//     console.log('parsed json !!', fetchedSupply);
//     const mySupply = json.result;
//   }).catch(function(ex) {
//     console.log('parsing failed', ex);
//   })
//   return fetchedSupply;
// }
// const fetchedSupply = getTotalSupply();
// console.log('fetchedSupply', fetchedSupply);

const initialState = {
  loading: false,
  totalSupply: 0,
  cost: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
